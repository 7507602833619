import Minilog from "minilog";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import axios from "axios";
import dayjs from "dayjs";

const LOCALSTORAGE_CONFIG_KEY = "loginet_internal_config";

/**
 * Sets up a configuration JSON in localStorage and stores the value on the key
 * @param key
 * @param value
 */
export function localStore(key, value) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    let parsed = JSON.parse(stored);
    parsed[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(parsed));
  } else {
    let data = {};
    data[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(data));
  }
}

/**
 * Returns the value stored in the config JSON
 * @param key
 * @returns {undefined|*}
 */
export function localFetch(key) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    // localStorage returns null if not exists
    let parsed = JSON.parse(stored);
    return parsed[key];
  } else {
    return undefined; //parsed[key] returns undefined if not exists, so we do it too
  }
}

export function isDevMode() {
  /* eslint-disable */
    return process.env.NODE_ENV === 'development';
}

export function newLogger(name) {
    if (isDevMode() === true) {
        Minilog.enable();
    }
    return Minilog(name || 'app');
}


export function formatDate(date) {
    return dayjs(date).format('DD.MM.YYYY');
}

export function formatDateISO(date) {
    return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateWithoutYear(date) {
    return dayjs(date).format('MM-DD');
}

export function formatDateWithMonthName(date) {
    return dayjs(date).format('YYYY. MMMM DD');
}

export function formatDayWithMonthName(date) {
    return dayjs(date).format('MMMM D.');
}

export function formatDayWithMonthNameShort(date) {
    return dayjs(date).format('MMM. DD');
}

export function formatDateTime(date) {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function getMonthName(date){
    return dayjs(date).format('MMMM');
}

export function addDay(date, numberOfDays){
    return dayjs(date).add(numberOfDays, 'day').format('YYYY-MM-DD');
}

export function subtractDay(date, numberOfDays){
    return dayjs(date).subtract(numberOfDays, 'day').format('YYYY-MM-DD');
}

export function normalize(object) {
    return object.toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
}

export function employeeFilter(str, employees) {
        return employees.filter(element => {
            if (!str || !element.name) return false;
            return (
                normalize(element.name)
                    .indexOf(
                        normalize(
                            str)
                    ) >= 0 ||
                (element.handle && normalize(element.handle)
                    .indexOf(normalize(str))
                    ) >= 0);
        });
}

export function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

export function openDeleteConfirm(buefy, onConfirmCallback, message = "Biztos vagy benne, hogy <b>törölni</b> szeretnéd?") {
    buefy.dialog.confirm({
        title: "Törlés megerősítés",
        message: message,
        confirmText: "Igen",
        cancelText: "Mégsem",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => onConfirmCallback()
    });
}

export function createUrlSearchParams(pagination, sort) {
    const { page, size } = pagination;
    const sortParam =
        sort.field !== null
            ? `${sort.field},${sort.order}`
            : null;

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("page", page);
    urlSearchParams.append("size", size);
    urlSearchParams.append("sort", sortParam);

    return urlSearchParams;
}

export function getDisplayName(employees, user) {
    let employee = employees.filter(emp => {
        return emp.id === user;
    });
    if (employee[0]) return employee[0].name;
    return user;
}

export function formatMoney(amount) {
    if (!amount || isNaN(amount) ) return 0;

    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft";
}


export function deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
}

export const numberify = (str) => {
    if (!str) {
        return 0;
    }
    if (typeof str !== "number") {
        return parseInt(str.replaceAll(" ", ""));
    } else {
        return str;
    }
};

export function moneyify(number) {
    if (!number) return "0";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function percentify(value) {
    return roundToTwoDecimals(value * 100) + "%";
}
export function roundToTwoDecimals(value) {
    return roundToNDecimals(value, 2);
}

export function roundToNDecimals(value, decimals) {
    const divider = Math.pow(10, decimals);
    return Math.round(value * divider) / divider;
}

export function toShortFormMoney(value) {
    let divided;
    let suffix = '';
    if(value > 1_000_000) {
        divided = value / 1_000_000;
        suffix = 'm';
    } else if(value > 1_000) {
        divided = value / 1_000;
        suffix = 'e';
    }

    return roundToTwoDecimals(divided) + `${suffix} Ft`;
}

export function secondsToHoursAndMinutes(time) {
    let hours = Math.floor(time / 60 / 60);
    let minutes = time / 60 % 60;
    let formatted = `${hours} óra`;
    if (minutes !== 0) {
        formatted = formatted.concat(` ${minutes} perc`);
    }
    return formatted;
}

export function secondToTimeFormat(time) {
    let hours = time > 0 ? Math.floor(time / 60 / 60) : Math.ceil(time / 60 / 60);
    let minutes = Math.abs(time / 60 % 60);
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

export function secondToDayFormat(time) {
    let days = Math.floor(time / 60 / 60 / 24);
    let hours = Math.floor(time / 60 / 60 % 24);
    let minutes = Math.round(time / 60 % 60);
    let formattedString = "";
    formattedString = formattedString.concat(days > 0 ? days + "d " : "");
    formattedString = formattedString.concat(hours + "h ");
    formattedString = formattedString.concat(minutes + "m");
    return formattedString;
}

export function secondToShortWorkDayFormat(time) {
    let days = Math.floor(time / 60 / 60 / 8);
    let hours = Math.floor(time / 60 / 60 % 8);
    let minutes = Math.round(time / 60 % 60);
    let formattedString = "";
    formattedString = formattedString.concat(Math.abs(days) > 0 ? days + "d " : "");
    formattedString = formattedString.concat(Math.abs(hours) > 0 ? hours + "h " : "");
    formattedString = formattedString.concat(Math.abs(minutes) > 0? minutes + "m" : "");
    if(formattedString === "") return '-';
    return formattedString;
}

export function secondToWorkDayFormat(time) {
    let days = Math.floor(time / 60 / 60 / 8);
    let hours = Math.floor(time / 60 / 60 % 8);
    let minutes = Math.round(time / 60 % 60);
    let formattedString = "";
    formattedString = formattedString.concat(Math.abs(days) > 0 ? days + " embernap " : "");
    formattedString = formattedString.concat(Math.abs(hours) > 0 ? hours + " óra " : "");
    formattedString = formattedString.concat(Math.abs(minutes) > 0? minutes + " perc" : "");
    if(formattedString === "") return '-';
    return formattedString;
}

export function convertSeconds(seconds) {
    if (!seconds) {
        return 0;
    }
    let inMinutes = Math.floor(seconds / 60);
    let hours = Math.floor(inMinutes / 60);
    let minutes = inMinutes % 60;
    return `${hours ? hours + "h" : ""}${minutes ? minutes + "m" : ""}`;
}

let removeSuffix = (string) => {
    if (string.endsWith('/')) {
        return removeSuffix(string.substring(0, string.length - 1))
    }
    return string
}

let addProtocol = (string) => {
    if (string.startsWith("https://")) {
        return string
    }
    if (string.startsWith("http://")) {
        return string.replace("http://", "https://")
    }
    return "https://".concat(string)
}

let toBrowseUrl = (string) => {
    if(!string) return ""
    return removeSuffix(addProtocol(string)).concat(`/browse/`)
}

export function createTicketUrl(key) {
    return toBrowseUrl(this.$store.getters["session/jiraUrl"]).concat(key);
}

export const thousandSeparator = createNumberMask({
    prefix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: " ",
    allowNegative: true,
});

export function getPathWithoutParam(route) {
    let path = route.fullPath
    Object.values(route.params).forEach(param => {
       path = path.replace(`/${param}`, '');
    });
    if (path.endsWith("/")) {
        path = path.slice(0, -1);
    }
    return path;
}

export function genericAutocompleteFilter(filterText, selectableElements, filterKey, selectedElements, selectedKey, filteredKey) {
    let filteredElements = selectableElements;
    if (filterText) {
        filteredElements = selectableElements.filter((selectable) => {
            return (
                selectable[filterKey]
                    .toString()
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) >= 0
            );
        });
    }
    if (selectedElements) {
        filteredElements = filteredElements.filter(
            (filtered) =>
                !selectedElements.some(
                    (selected) => selected[selectedKey] === filtered[filteredKey]
                )
        );
    }
    return filteredElements;
}

export function getTotalSecondsCss(dailyTotalSeconds) {
    if (dailyTotalSeconds < 28800) {
        return "is-danger";
    }
    if (dailyTotalSeconds === 28800) {
        return "is-success";
    }
    if (dailyTotalSeconds > 28800) {
        return "is-primary";
    }
}

export function correctWithTimezoneOffset(date) {
    if (date) { // fixme: ez hülyeséget csinált nyári időszámításkor
        if (date.getHours() === 0) {
            // return new Date(date.setHours(date.getHours() + (new Date().getTimezoneOffset() / -60)));
            date.setHours(12);
        }
        return date;
    }
    return null;
}

export function getWeekOfMonth(date) {
    const d = new Date(+date);
    const f = new Date(d.getFullYear(), d.getMonth(), 1);
    let dD = d.getDay() - 1;
    if(dD === -1) dD = 6;
    let fD = f.getDay() - 1;
    if(fD === -1) fD = 6;
    d.setDate(d.getDate() - dD + 1);
    f.setDate(f.getDate() - fD + 1);
    const fM = +f.getMonth() + 1;
    let v = {month: +d.getMonth()+1,
        week: Math.ceil(d.getDate()/7)};
    if(fM === date.getMonth()) v.week += 1;
    return v.week === 6 ? 1 : v.week;
}

//https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
export function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

export function getContrastedColor(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    result = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;

    let brightness = Math.round(
        (parseInt(result.r * 299) +
            parseInt(result.g) * 587 +
            parseInt(result.b) * 114) /
        1000
    );

    return brightness > 125 ? "#000000" : "#FFFFFF";
}

export function getContrastedColorCSS(hex) {
    return "color: " + getContrastedColor(hex);
}

export async function getSessionData() {
    try {
        let ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;

        let response = (await axios.get(`https://ipapi.co/${ip}/json`)).data;

        let country = response.country;

        let city = response.city;
        const unknown = '-';

        // screen

        let screenSize = '';
        let width = '';
        let height = '';
        if (screen.width) {
            width = (screen.width) ? screen.width : '';
            height = (screen.height) ? screen.height : '';
            screenSize += '' + width + " x " + height;
        }
        // browser

        const nVer = "";
        const nAgt = navigator.userAgent;
        let browser = "Ismeretlen böngésző";
        let version = '';
        let majorVersion;
        let nameOffset, verOffset, ix;

        // Opera
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Opera Next
        if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 4);
        }
        // Legacy Edge
        else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
            browser = 'Microsoft Legacy Edge';
            version = nAgt.substring(verOffset + 5);
        }
        // Edge (Chromium)
        else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
            browser = 'Microsoft Edge';
            version = nAgt.substring(verOffset + 4);
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(verOffset + 5);
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browser = 'Chrome';
            version = nAgt.substring(verOffset + 7);
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browser = 'Safari';
            version = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browser = 'Firefox';
            version = nAgt.substring(verOffset + 8);
        }
        // MSIE 11+
        else if (nAgt.indexOf('Trident/') !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(nAgt.indexOf('rv:') + 3);
        }
        // Other browsers
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browser = nAgt.substring(nameOffset, verOffset);
            version = nAgt.substring(verOffset + 1);
            if (browser.toLowerCase() === browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

        majorVersion = parseInt('' + version, 10);
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(nVer);
            majorVersion = parseInt(nVer, 10);
        }

        // mobile version
        const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        let cookieEnabled = (navigator.cookieEnabled);

        if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = (document.cookie.indexOf('testcookie') !== -1);
        }

        // system
        let os = unknown;
        const clientStrings = [
            {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
            {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
            {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
            {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
            {s: 'Windows Vista', r: /Windows NT 6.0/},
            {s: 'Windows Server 2003', r: /Windows NT 5.2/},
            {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
            {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
            {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
            {s: 'Windows 98', r: /(Windows 98|Win98)/},
            {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
            {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
            {s: 'Windows CE', r: /Windows CE/},
            {s: 'Windows 3.11', r: /Win16/},
            {s: 'Android', r: /Android/},
            {s: 'Open BSD', r: /OpenBSD/},
            {s: 'Sun OS', r: /SunOS/},
            {s: 'Chrome OS', r: /CrOS/},
            {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
            {s: 'iOS', r: /(iPhone|iPad|iPod)/},
            {s: 'Mac OS X', r: /Mac OS X/},
            {s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
            {s: 'QNX', r: /QNX/},
            {s: 'UNIX', r: /UNIX/},
            {s: 'BeOS', r: /BeOS/},
            {s: 'OS/2', r: /OS\/2/},
            {s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
        ];
        for (const id in clientStrings) {
            const cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = unknown;

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS':
            case 'Mac OS X':
            case 'Android':
                osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;
        }
        return {
            'device': os +' '+ osVersion,
            'browser': browser +' '+ majorVersion + ' (' + version + ')',
            'Mobile': mobile,
            'location': city +', '+ country,
            'Cookies': cookieEnabled,
            'Screen Size': screenSize,
            'Full User Agent': navigator.userAgent
        };
    } catch(error) {
        console.error(error);
        return {
            'device': "Ismeretlen",
            'browser': "",
            'Mobile': "",
            'location': "",
            'Cookies': "",
            'Screen Size': "",
            'Full User Agent': ""
        };
    }
}

export function stringToColour(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export function isNullOrUndefined(val) {
    return val === null || val === undefined;
}

String.prototype.snakeToCamel = function() {
    let copy = this;
    copy = copy.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
    return copy;
}

String.prototype.camelToUpperSnake = function() {
    let copy = this;
    copy = copy.toLowerCase().replace(/([A-Z])/g, letter => `_${letter}`).toUpperCase();
    return copy;
}

Date.prototype.addDaysSkippingWeekends = function(days) {
    const result = new Date(this.valueOf());
    let addedDays = 0;
    if(days < 0) {
        while (addedDays > days) {
            result.setDate(result.getDate() - 1);
            if (!(result.getDay() === 0
                || result.getDay() == 6)) {
                --addedDays;
            }
        }
        return result;
    } else {
        while (addedDays < days) {
            result.setDate(result.getDate() + 1);
            if (!(result.getDay() === 0
                || result.getDay() == 6)) {
                ++addedDays;
            }
        } 
    }
    return result;
}

Date.prototype.workDaysBetween = function(date) {
    let result = 0;
    let current = new Date(this.valueOf());
    while (current < date) {
        if (!(current.getDay() === 0
            || current.getDay() === 6)) {
            ++result;
        }
        current.setDate(current.getDate() + 1);
    }
    return result;
}

export function avg(...arr) {
    return arr.reduce((a, b) => a + b, 0) / arr.length;
}

export function formatDateRangeWithoutYear(dates) {
    return dates
        .map((date) => {
                if (Object.prototype.toString.call(date) === "[object Date]") {
                    // it is a date
                    if (isNaN(date)) { // d.getTime() or d.valueOf() will also work
                        // date object is not valid
                        return null;
                    } else {
                        // date object is valid
                        return new Intl.DateTimeFormat(navigator.language, {
                            day: "numeric",
                            month: "long",
                        }).format(date)
                    }
                } else {
                    // not a date object
                    return null;
                }
        }

        ).filter(it => it !== null)
        .join(" - ");
}

export function formatNumber(number) {
    if (number === undefined || number === null) return number;
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });
}

export function formatNumberHideZero(number) {
    if (number === undefined || number === null) return number;
    if(number.toString() === "0") return "";
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });
}