import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Country-Store");

const urls = {
  get: (id) => `/country/${id}`,
  all: "/country",
  delete: (id) => `/country/${id}`,
  create: "/country",
  update: (id) => `/country/${id}`,
};

const initState = () => {
  return {
    countries: [],
    country: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading countries...",
        force,
      });
      commit("saveCountries", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { countryCode, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(countryCode),
        dispatch,
        flair: "Loading country...",
        force,
      });
      commit("saveCountry", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving country...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating country...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting country...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveCountry: (state, data) => {
    Vue.set(state, "country", data);
  },
  saveCountries: (state, data) => {
    Vue.set(state, "countries", data);
  },
};

const getters = {
  country: (state) => {
    return state.country;
  },
  countries: (state) => {
    return state.countries ?? [];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
