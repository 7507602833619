import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Certification-Store");

const urls = {
  get: (id) => `/certification/${id}`,
  delete: (id) => `/certification/${id}`,
  all: "/certification",
  create: "/certification",
  update: (id) => `/certification/${id}`,
};

const initState = () => {
  return {
    certifications: [],
    certification: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.all,
        dispatch,
        flair: "Loading certifications...",
        force,
      });
      commit("saveCertifications", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  get: async function ({ commit, dispatch }, { certificationId, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.get(certificationId),
        dispatch,
        flair: "Loading certification...",
        force,
      });
      commit("saveCertification", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ commit, dispatch }, request) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.create,
        data: request,
        dispatch,
        flair: "Saving certification...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ commit, dispatch }, { id, request }) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.update(id),
        data: request,
        dispatch,
        flair: "Updating certification...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.delete(id),
        dispatch,
        flair: "Deleting certification...",
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveCertification: (state, data) => {
    Vue.set(state, "certification", data);
  },
  saveCertifications: (state, data) => {
    Vue.set(state, "certifications", data);
  },
};

const getters = {
  certification: (state) => {
    return state.certification;
  },
  certifications: (state) => {
    return state.certifications;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
