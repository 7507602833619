export const UNKNOWN = "Unknown";
export const UNKNOWN_USER = "Unknown user";
export const NA = "N/A";

export const DASHBOARD_FILTER_TYPE = {
  ENUM: {
    DATE_RANGE: "DATE_RANGE",
    SPECIFIC_DATE: "SPECIFIC_DATE",
  },
  LABEL: {
    DATE_RANGE: "Date range",
    SPECIFIC_DATE: "Specific date",
  },
};

export const DASHBOARD_FILTER_INTERVAL_TYPE = {
  WEEK: "By week",
  MONTH: "By month",
  YEAR: "By year",
  SEASON: "By season",
};

export const ROLES = {
  ADMIN: "0c426f7e-e10b-11ed-9f09-0242ac150002",
  TRADE_MANAGER: "91725a1b-f533-11ed-8bda-0242ac130002",
  WET_MILL_MANAGER: "91724698-f533-11ed-8bda-0242ac130002",
};
