import { newLogger } from "@/utils/util";
import Vue from "vue";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Clerk-Store");

const urls = {
  clerk: "/clerk",
  clerkWithId: (id) => `/clerk/${id}`,
  clerkForLocation: (id) => `/clerk/location/${id}`,
};

/**
 * Description of the store
 */

const initState = () => {
  return {
    clerk: {},
    clerks: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.clerkWithId(id),
        dispatch,
        flair: "Fetching clerk...",
        force,
      });
      commit("saveClerk", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  find: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let data = await this.makeRequest({
        method: "get",
        url: urls.clerk,
        data: params,
        dispatch,
        flair: "Loading clerks for location...",
        force,
      });
      commit("saveClerks", { id: params.params.get("locationId"), data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  create: async function ({ commit, dispatch }, data) {
    try {
      await this.makeRequest({
        method: "post",
        url: urls.clerk,
        data,
        dispatch,
        flair: "Saving clerk...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),

  update: async function ({ commit, dispatch }, { id, data } = {}) {
    try {
      await this.makeRequest({
        method: "put",
        url: urls.clerkWithId(id),
        data,
        dispatch,
        flair: "Updating clerk...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),

  delete: async function ({ commit, dispatch }, id) {
    try {
      await this.makeRequest({
        method: "delete",
        url: urls.clerkWithId(id),
        dispatch,
        flair: "Deleting clerk...",
        errorToast: false,
      });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject((err.response && err.response.data.message) || err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveClerk: (state, data) => {
    Vue.set(state, "clerk", data);
  },
  saveClerks: (state, { id, data }) => {
    Vue.set(state.clerks, id, data);
  },
};

const getters = {
  clerk: (state) => {
    return state.clerk;
  },
  clerks: (state) => (locationId) => {
    if (!locationId) return [];
    return state.clerks[locationId];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
